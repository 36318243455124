import { defineStore } from "pinia";
import { ethers } from "ethers";
import { signer, useWalletStore } from "@/store/wallet";
import {
  convertBigNumberToBigNumberJs,
  fetchDataFromChain,
  fetchTokenBalance,
  NULL_WALLET,
  publicProvider,
} from "@/utils/blockchain";
import smartContracts from "@/utils/SmartContracts";
import {
  BoosterNFTInfo,
  CompareChangeCallback,
  ContractNodeData,
  Distribution,
  LVPInfo,
  NodeData,
  Tier,
} from "../../types";
import { DateTime } from "luxon";
import BigNumberJs from "bignumber.js";
import { getTokenSmartContract } from "@/utils/tokenCache";
import { LVP } from "../../types/LVP";
import { LVPMigration } from "../../types/LVPMigration";
import { PermanentBoosterV2 } from "../../types/PermanentBoosterV2";

export interface LavaState {
  ratios: { [key: string]: { [key: string]: number } };
  lpPairRatio: number;
  decayedRewardFactor: number;
  userNodes: NodeData[];
  latestClaimTime: DateTime;
  isLoadingUserNodes: boolean;
  isLavaToPLavaSwapActive: boolean;
  // claimCooldownSeconds: number;
  amountGoldNFT: number;
  amountSilverNFT: number;
  amountBronzeNFT: number;
  totalNodes: number;
  investedAmount: ethers.BigNumber;
  claimedAmount: ethers.BigNumber;
  pendingRewards: ethers.BigNumber;
  totalLavaSupply: ethers.BigNumber;
  circulatingLavaSupply: ethers.BigNumber;
  nodeTiers: Tier[];
  claimTaxForSale: number;
  claimTaxIncrease: number;
  boosterNftsList: Array<BoosterNFTInfo>;
  LVPs: Array<LVPInfo>;
  claimableUsdcFromLvps: ethers.BigNumber;
  totalClaimedUsdcFromLvps: ethers.BigNumber;
  migration: {
    alreadyMigrated: boolean;
    info: {
      nftCount: number;
      usdcPayout: ethers.BigNumber;
      remainingMintabeNFt: number;
    };
    minNftAmount: number;
    maxClaimableUsdc: ethers.BigNumber;
    unclaimedRewards: ethers.BigNumber;
    pLavaUnclaimedRewards: ethers.BigNumber;
  };
  stats: {
    LVPSupply: number;
    boostedLVPsLvl1: number;
    boostedLVPsLvl2: number;
    boostedLVPsLvl3: number;
    totalUsdcDistributedAmount: ethers.BigNumber;
  };
  isAdmin: boolean;
  roles: {
    lvpDistributor: boolean;
    lvpMinter: boolean;
    boosterMinter: boolean;
  };
  distributionsHistory: Distribution[];
}

export const tokensToBuyWith = [smartContracts.pLAVA.address, smartContracts.LAVAv2.address, smartContracts.erc20.USDC];

export const boosterMultipliers = {
  1: 105,
  2: 110,
  3: 115,
};

export const timestampToAccessLevel = (creationTimestamp: number) => {
  // 5/23/22
  // Date and time (GMT): Monday, 23 May 2022 00:00:00
  if (creationTimestamp <= 1653264000) {
    return 6;
    // 5/30/22
    // Date and time (GMT): Monday, 30 May 2022 00:00:00
  } else if (creationTimestamp <= 1653868800) {
    return 5;
    // 6/29/22
    // Date and time (GMT): Wednesday, 29 June 2022 00:00:00
  } else if (creationTimestamp <= 1656460800) {
    return 4;
    // 7/7/22
    // Date and time (GMT): Thursday, 7 July 2022 00:00:00
  } else if (creationTimestamp <= 1657152000) {
    return 3;
    // 7/27/22
    // Date and time (GMT): Wednesday, 27 July 2022 00:00:00
  } else if (creationTimestamp <= 1658880000) {
    return 2;
    // 8/18/22
    // Date and time (GMT): Thursday, 18 August 2022 00:00:00
  } else if (creationTimestamp <= 1660780800) {
    return 1;
    // After 8/18/22
  } else {
    return 0;
  }
};

export const accessLevelToTimestamp = (accessLevel: number) => {
  switch (accessLevel) {
    case 6:
      return 1653264000;
    case 5:
      return 1653868800;
    case 4:
      return 1656460800;
    case 3:
      return 1657152000;
    case 2:
      return 1658880000;
    case 1:
      return 1660780800;
    default:
      return Math.round(Date.now() / 1000);
  }
};

// NOTE: these variables are placed intentionally outside pinia store management, as these objects don't like proxies and don't work properly
export let idoContract: ethers.Contract | undefined;
export let lavaFinanceContract: ethers.Contract | undefined;
export let lavaV2Contract: ethers.Contract | undefined;
export let oracleContract: ethers.Contract | undefined;
export let lvpMigrationContract: LVPMigration | undefined;
export let lvpContract: LVP | undefined;
export let bronzeBoosterContract: PermanentBoosterV2 | undefined;
export let silverBoosterContract: PermanentBoosterV2 | undefined;
export let goldBoosterContract: PermanentBoosterV2 | undefined;

export const useLavaStore = defineStore("lava", {
  state(): LavaState {
    return {
      ratios: {},
      lpPairRatio: 10000,
      decayedRewardFactor: 10000,
      userNodes: [],
      latestClaimTime: DateTime.fromSeconds(0),
      isLoadingUserNodes: false,
      isLavaToPLavaSwapActive: false,
      // claimCooldownSeconds: 0,
      amountGoldNFT: 0,
      amountSilverNFT: 0,
      amountBronzeNFT: 0,
      totalNodes: 0,
      investedAmount: ethers.BigNumber.from(0),
      claimedAmount: ethers.BigNumber.from(0),
      pendingRewards: ethers.BigNumber.from(0),
      totalLavaSupply: ethers.BigNumber.from(0),
      circulatingLavaSupply: ethers.BigNumber.from(0),
      claimTaxForSale: 500,
      nodeTiers: [
        {
          blockchainId: 1,
          title: "Fuji",
          cost: 100,
          reward: new BigNumberJs(0.8),
          fees: ethers.BigNumber.from(0),
        },
        {
          blockchainId: 2,
          title: "Krakatoa",
          cost: 200,
          reward: new BigNumberJs(2),
          fees: ethers.BigNumber.from(0),
        },
        {
          blockchainId: 3,
          title: "Novarupta",
          cost: 500,
          reward: new BigNumberJs(6),
          fees: ethers.BigNumber.from(0),
        },
      ],
      claimTaxIncrease: 1500,
      migration: {
        alreadyMigrated: false,
        info: {
          nftCount: 0,
          usdcPayout: ethers.BigNumber.from(0),
          remainingMintabeNFt: 0,
        },
        minNftAmount: 0,
        maxClaimableUsdc: ethers.BigNumber.from(0),
        unclaimedRewards: ethers.BigNumber.from(0),
        pLavaUnclaimedRewards: ethers.BigNumber.from(0),
      },
      boosterNftsList: [],
      LVPs: [],
      claimableUsdcFromLvps: ethers.BigNumber.from(0),
      totalClaimedUsdcFromLvps: ethers.BigNumber.from(0),
      stats: {
        LVPSupply: 0,
        boostedLVPsLvl1: 0,
        boostedLVPsLvl2: 0,
        boostedLVPsLvl3: 0,
        totalUsdcDistributedAmount: ethers.BigNumber.from(0),
      },
      isAdmin: false,
      roles: {
        lvpDistributor: false,
        lvpMinter: false,
        boosterMinter: false,
      },
      distributionsHistory: [],
    };
  },
  getters: {
    wallet() {
      return useWalletStore();
    },
    referralLink() {
      if (!this.wallet.address) {
        return null;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const ref = Buffer.from(this.wallet.address).toString("base64");
      return `${window.location.origin}/swap?ref=${ref}`;
    },
    nodesTotalWorth(): number {
      return this.userNodes.map((node) => node.tier.cost).reduce((a, b) => a + b, 0);
    },
    monthlyFees(): ethers.BigNumber {
      return this.userNodes.map((node) => node.feesPerMoth).reduce((a, b) => a.add(b), ethers.BigNumber.from(0));
    },
    pastDueFees(): ethers.BigNumber {
      return this.userNodes
        .filter((node) => node.paymentExpiry.diffNow().milliseconds < 1000)
        .map((node) => node.feesPerMoth)
        .reduce((a, b) => a.add(b), ethers.BigNumber.from(0));
    },
    baseRewardsPerDay(): Omit<BigNumberJs, "_isBigNumber"> {
      return this.gotRoiAlready
        ? new BigNumberJs(0)
        : this.userNodes.map((node) => node.tier.reward).reduce((a, b) => a.plus(b), new BigNumberJs(0));
    },
    basePLavaRewardsPerDay(): Omit<BigNumberJs, "_isBigNumber"> {
      return !this.gotRoiAlready
        ? new BigNumberJs(0)
        : this.userNodes
            .filter((node) => node.isCompounded)
            .map((node) => node.tier.reward.div(2))
            .reduce((a, b) => a.plus(b), new BigNumberJs(0));
    },
    gotRoiAlready(): boolean {
      return this.investedAmount.lte(this.claimedAmount);
    },
    activeNFTBoostPercent(): number {
      if (this.hasGoldNFT) {
        return 15;
      } else if (this.hasSilverNFT) {
        return 10;
      } else if (this.hasBronzeNFT) {
        return 5;
      }
      return 0;
    },
    userNodesById(): { [key: number]: NodeData } {
      const obj = {};
      this.userNodes.forEach((node) => {
        obj[node.id] = node;
      });
      return obj;
    },
    cheapestTier(): Tier {
      return this.nodeTiers.reduce((a, b) => (a.cost < b.cost ? a : b), this.nodeTiers[0]);
    },
    mostExpensiveTier(): Tier {
      return this.nodeTiers.reduce((a, b) => (a.cost > b.cost ? a : b), this.nodeTiers[0]);
    },
    nodeTiersById(): { [key: number]: Tier } {
      const nodeTiersById = {};
      this.nodeTiers.forEach((tier) => {
        nodeTiersById[tier.blockchainId] = tier;
      });
      return nodeTiersById;
    },
    hasGoldNFT(): boolean {
      return this.amountGoldNFT > 0;
    },
    hasSilverNFT(): boolean {
      return this.amountSilverNFT > 0;
    },
    hasBronzeNFT(): boolean {
      return this.amountBronzeNFT > 0;
    },
    totalLvpPoints(): number {
      return (
        this.stats.boostedLVPsLvl1 * boosterMultipliers[1] +
        this.stats.boostedLVPsLvl2 * boosterMultipliers[2] +
        this.stats.boostedLVPsLvl3 * boosterMultipliers[3] +
        this.baseLvpPoints
      );
    },
    baseLvpPoints(): number {
      return (
        (this.stats.LVPSupply - this.stats.boostedLVPsLvl1 - this.stats.boostedLVPsLvl2 - this.stats.boostedLVPsLvl3) *
        100
      );
    },
  },
  actions: {
    async initPublic() {
      this.fetchClaimTaxForSale();
      this.fetchPublicLVPData();
    },
    async init() {
      idoContract = new ethers.Contract(smartContracts.IDO.address, smartContracts.IDO.ABI, signer!);
      lavaFinanceContract = new ethers.Contract(
        smartContracts.LavaFinance.address,
        smartContracts.LavaFinance.ABI,
        signer!
      );
      lavaV2Contract = new ethers.Contract(smartContracts.LAVAv2.address, smartContracts.LAVAv2.ABI, signer!);
      oracleContract = new ethers.Contract(smartContracts.Oracle.address, smartContracts.Oracle.ABI, signer!);
      lvpMigrationContract = new ethers.Contract(
        smartContracts.LVPMigration.address,
        smartContracts.LVPMigration.ABI,
        signer!
      ) as LVPMigration;
      lvpContract = new ethers.Contract(smartContracts.LVP.address, smartContracts.LVP.ABI, signer!) as LVP;
      bronzeBoosterContract = new ethers.Contract(
        smartContracts.bronzeNFT.address,
        smartContracts.PermanentBoosterV2.ABI,
        signer!
      ) as PermanentBoosterV2;
      silverBoosterContract = new ethers.Contract(
        smartContracts.silverNFT.address,
        smartContracts.PermanentBoosterV2.ABI,
        signer!
      ) as PermanentBoosterV2;
      goldBoosterContract = new ethers.Contract(
        smartContracts.goldNFT.address,
        smartContracts.PermanentBoosterV2.ABI,
        signer!
      ) as PermanentBoosterV2;

      this.fetchWalletRoles();
      this.fetchIsLavaToPLavaSwapActive();
      this.fetchDecayedRewardFactor();
      this.fetchTotalNodes();
      // await this.fetchClaimCooldown();
      await this.fetchTiers();
      await this.fetchClaimTaxIncrease();
      await this.fetchWalletRoiData();
      this.fetchPendingRewards();
      this.fetchRatios();
      await this.fetchNodes();
      await this.fetchBoosterNftsAmounts();
      await this.fetchLVPs();
      await this.fetchBoosterNftsDetails();
      this.fetchClaimableUsdcFromLvps();
      this.fetchTotalClaimedUsdcFromLvps();
      this.fetchMigrationInfos();
    },
    reset() {
      idoContract = undefined;
      lavaFinanceContract = undefined;
      lavaV2Contract = undefined;
      oracleContract = undefined;
      lvpMigrationContract = undefined;
      lvpContract = undefined;

      this.latestClaimTime = DateTime.fromSeconds(0);
      this.userNodes = [];
      // this.claimCooldownSeconds = 0;
      this.amountGoldNFT = 0;
      this.amountSilverNFT = 0;
      this.amountBronzeNFT = 0;
      this.investedAmount = ethers.BigNumber.from(0);
      this.claimedAmount = ethers.BigNumber.from(0);
      this.isAdmin = false;
      this.roles.lvpMinter = false;
      this.roles.lvpDistributor = false;
      this.roles.boosterMinter = false;

      this.boosterNftsList = [];
      this.LVPs = [];
      this.claimableUsdcFromLvps = ethers.BigNumber.from(0);
      this.totalClaimedUsdcFromLvps = ethers.BigNumber.from(0);
    },
    async fetchTiers() {
      if (!lavaFinanceContract) {
        return;
      }
      const rewardsMultiplier = await lavaFinanceContract.rewardsMultiplier();
      for (let i = 1; i <= 3; i++) {
        const tierData = await lavaFinanceContract.tiers(i);
        const tier = this.nodeTiersById[i];
        tier.fees = await lavaFinanceContract.getMaintenanceFeesForTier(i);
        tier.reward = convertBigNumberToBigNumberJs(tierData.reward.mul(rewardsMultiplier).div(10000));
        tier.cost = parseInt(ethers.utils.formatEther(tierData.cost));
      }
    },
    async fetchClaimTaxIncrease() {
      if (!lavaFinanceContract) {
        return;
      }
      this.claimTaxIncrease = (await lavaFinanceContract.claimTaxIncrease()).toNumber();
    },
    async fetchRatios() {
      if (!lavaFinanceContract) {
        return;
      }
      for (let i = 0; i < tokensToBuyWith.length; i++) {
        for (let j = 0; j < this.nodeTiers.length; j++) {
          const ratio = await lavaFinanceContract.Ratios(this.nodeTiers[j].blockchainId, tokensToBuyWith[i]);
          if (!this.ratios[this.nodeTiers[j].blockchainId]) {
            this.ratios[this.nodeTiers[j].blockchainId] = {};
          }
          this.ratios[this.nodeTiers[j].blockchainId][tokensToBuyWith[i]] = ratio.toNumber();
        }
      }

      this.lpPairRatio = (await lavaFinanceContract.lpPairRatio()).toNumber();
    },
    async fetchMigrationInfos() {
      if (!lvpMigrationContract) {
        return;
      }
      this.migration.alreadyMigrated = await lvpMigrationContract.isMigrated(this.wallet.address);
      if (this.migration.alreadyMigrated) {
        const migrationData = await lvpMigrationContract.getMigrationInfo(this.wallet.address);
        this.migration.info.nftCount = migrationData.nftCount.toNumber();
        this.migration.info.usdcPayout = migrationData.usdcPayout;
        this.migration.info.remainingMintabeNFt = migrationData.remainingMintabeNFt.toNumber();
      } else {
        this.migration.info.nftCount = 0;
        this.migration.info.usdcPayout = ethers.BigNumber.from(0);
        this.migration.info.remainingMintabeNFt = 0;
      }

      if (this.userNodes.length > 0) {
        this.migration.minNftAmount = (
          await lvpMigrationContract.getAggregatedNftCount(this.wallet.address)
        )[0].toNumber();
        this.migration.maxClaimableUsdc = await lvpMigrationContract.getMaxPayoutInUsdc(this.wallet.address);

        const claimAmount = await lavaFinanceContract!.getClaimAmount(
          this.wallet.address,
          this.userNodes.map((node) => node.id),
          10000,
          NULL_WALLET,
          true
        );
        this.migration.unclaimedRewards = claimAmount[1];
        this.migration.pLavaUnclaimedRewards = claimAmount[2];
      } else {
        this.migration.minNftAmount = 0;
        this.migration.maxClaimableUsdc = ethers.BigNumber.from(0);
        this.migration.unclaimedRewards = ethers.BigNumber.from(0);
        this.migration.pLavaUnclaimedRewards = ethers.BigNumber.from(0);
      }
    },
    async fetchDecayedRewardFactor() {
      if (!lavaFinanceContract) {
        return;
      }
      this.decayedRewardFactor = (await lavaFinanceContract.decayedRewardFactor()).toNumber();
    },
    async fetchIsLavaToPLavaSwapActive() {
      if (!lavaV2Contract) {
        return;
      }
      this.isLavaToPLavaSwapActive = await lavaV2Contract.isLavaToPLavaSwapActive();
    },
    async fetchClaimTaxForSale() {
      const lavaContract = new ethers.Contract(
        smartContracts.LavaFinance.address,
        smartContracts.LavaFinance.ABI,
        publicProvider
      );
      if (!lavaContract) {
        return;
      }
      this.claimTaxForSale = (await lavaContract.claimTaxToSell()).toNumber();
    },
    async fetchPublicLVPData() {
      const publicLvpContract = new ethers.Contract(
        smartContracts.LVP.address,
        smartContracts.LVP.ABI,
        publicProvider
      ) as LVP;
      if (!publicLvpContract) {
        return;
      }

      this.distributionsHistory = [];
      const latestSnapshot = (await publicLvpContract.getCurrentSnapshotId()).toNumber();
      const fetchPerRequest = 100;
      for (let i = 1; i <= latestSnapshot; i += fetchPerRequest) {
        const tillSnapshot = Math.min(latestSnapshot, i + (fetchPerRequest - 1));
        const history = await publicLvpContract.getDistributionHistory(i, tillSnapshot);
        history.forEach((distribution) => {
          this.distributionsHistory.push({
            timestamp: DateTime.fromSeconds(distribution.timestamp.toNumber()),
            baseUsdcPerLvp: distribution.baseUsdcPerLvp,
            usdcAmount: distribution.usdcAmount,
            source: distribution.source,
          });
        });
      }

      this.stats.LVPSupply = (await publicLvpContract.totalSupply()).toNumber();
      this.stats.totalUsdcDistributedAmount = await publicLvpContract.totalUsdcDistributedAmount();
    },
    async fetchTotalNodes() {
      if (!lavaFinanceContract) {
        return;
      }
      this.totalNodes = (await lavaFinanceContract.nextMinted()).toNumber();
    },
    async fetchLavaSupply() {
      const lavaContract = getTokenSmartContract(smartContracts.LAVAv2.address);
      if (!lavaContract) {
        return;
      }
      this.totalLavaSupply = await lavaContract.totalSupply();

      const deployerBalance = await lavaContract.balanceOf("0x503e333106b36dff677236c0d5ea217f5401624c");
      const liquidityManagerBalance = await lavaContract.balanceOf("0x08232d0e71d5bb3dc3ab0dece71c8b6084210a58");
      const lavav2Balance = await lavaContract.balanceOf("0x7c105c37a622eb8586beaef215d452b3f7dc9a39");
      const rewardPoolBalance = await lavaContract.balanceOf("0xDe7E9fd01018C59DEB46bC36316da555Eb889a27");
      const lpBalance = await lavaContract.balanceOf("0xeCfa93907280C19AAF72b9f122B5d0cF4ECfD1A4");
      const popsLpBalance = await lavaContract.balanceOf("0x8D7040CCD38f7bE43E09F72AD9b858c64b8c2D74");
      const lpWalletBalance = await lavaContract.balanceOf("0x593f5fE07d268d42A20feFa3A0c131e48667cBa9");
      const treasuryBalance = await lavaContract.balanceOf("0xCe89af8995b6A510286341978ebf742DA7801A57");
      this.circulatingLavaSupply = this.totalLavaSupply
        .sub(deployerBalance)
        .sub(liquidityManagerBalance)
        .sub(lavav2Balance)
        .sub(rewardPoolBalance)
        .sub(lpBalance)
        .sub(popsLpBalance)
        .sub(lpWalletBalance)
        .sub(treasuryBalance);
    },
    async fetchWalletRoiData() {
      if (!lavaFinanceContract) {
        return;
      }

      this.investedAmount = await lavaFinanceContract.investedAmount(this.wallet.address);
      this.claimedAmount = await lavaFinanceContract.claimedAmount(this.wallet.address);
    },
    async fetchNodes(shouldChange = false) {
      if (this.isLoadingUserNodes) {
        return;
      }
      let valueChangeCompareCallback: CompareChangeCallback<NodeData[]> | undefined;

      if (shouldChange) {
        valueChangeCompareCallback = (newValue) => {
          if (newValue.length === 0) {
            return true;
          }
          return (
            newValue.length !== this.userNodes.length ||
            !newValue
              .reduce((a, b) => a.add(b.rewardsPending), ethers.BigNumber.from(0))
              .eq(this.userNodes.reduce((a, b) => a.add(b.rewardsPending), ethers.BigNumber.from(0))) ||
            !newValue
              .reduce((a, b) => a.add(b.pLavaRewardsPending), ethers.BigNumber.from(0))
              .eq(this.userNodes.reduce((a, b) => a.add(b.pLavaRewardsPending), ethers.BigNumber.from(0)))
          );
        };
      }

      this.isLoadingUserNodes = true;
      this.userNodes = await fetchDataFromChain(async () => {
        if (!this.wallet.address || !lavaFinanceContract) {
          return [];
        }
        const userNodes = await lavaFinanceContract.getUserNodes(this.wallet.address);

        const nodesData = await Promise.all<ContractNodeData & { id: number }>(
          userNodes.map(async (nodeId) => {
            const response = await lavaFinanceContract!.nodeData(nodeId);
            return {
              id: nodeId.toNumber(),
              ...response,
            };
          })
        );

        const allNodes = await Promise.all<NodeData>(
          nodesData.map(async (nodeData) => {
            const getClaimAmount = await lavaFinanceContract!.getClaimAmount(
              this.wallet.address,
              [nodeData.id],
              10000,
              NULL_WALLET,
              false
            );

            const feesPerMoth = this.nodeTiersById[nodeData.tier.toNumber()].fees.mul(this.gotRoiAlready ? 0 : 1);

            const lastClaim = DateTime.fromSeconds(nodeData.lastClaim.toNumber());

            if (lastClaim > this.latestClaimTime) {
              this.latestClaimTime = lastClaim;
            }

            return {
              id: nodeData.id,
              tier: this.nodeTiersById[nodeData.tier.toNumber()],
              name: nodeData.name,
              isCompounded: nodeData.isCompounded,
              feesPerMoth,
              lastClaim,
              creationTime: DateTime.fromSeconds(nodeData.creationTime.toNumber()),
              rewardsPending: getClaimAmount[1],
              pLavaRewardsPending: getClaimAmount[2],
              paymentExpiry: DateTime.fromSeconds(nodeData.paymentExpiry.toNumber()),
            };
          })
        );

        // timerStore.startTimer(
        //   this.latestClaimTime
        //     .plus({
        //       seconds: this.claimCooldownSeconds,
        //     })
        //     .toSeconds(),
        //   false
        // );

        return allNodes;
      }, valueChangeCompareCallback);
      this.isLoadingUserNodes = false;
    },
    async fetchLVPs() {
      this.LVPs = await this.fetchLVPsOf(this.wallet.address);
      for (let i = 0; i < this.LVPs.length; i++) {
        this.LVPs[i].boostedAmount = i < 40 ? this.activeNFTBoostPercent : 0;
      }
    },
    async fetchLVPsOf(address: string) {
      return fetchDataFromChain(async () => {
        const LVPs: LVPInfo[] = [];
        if (!lvpContract) {
          return LVPs;
        }

        const data = await lvpContract.tokenIdsOfOwner(address);
        for (let i = 0; i < data[0].length; i++) {
          LVPs.push({
            tokenId: data[0][i].toNumber(),
            creationDate: data[1][i].toNumber(),
            accessLevel: timestampToAccessLevel(data[1][i].toNumber()),
          });
        }
        return LVPs;
      });
    },
    async fetchClaimableUsdcFromLvps() {
      this.claimableUsdcFromLvps = await lvpContract!.getClaimableAmount(this.wallet.address);
    },
    async fetchTotalClaimedUsdcFromLvps() {
      this.totalClaimedUsdcFromLvps = await lvpContract!.totalUsdcClaimed(this.wallet.address);
    },
    async fetchPendingRewards() {
      const getClaimAmount = await lavaFinanceContract!.getClaimAmount(
        this.wallet.address,
        [],
        10000,
        NULL_WALLET,
        true
      );

      this.pendingRewards = getClaimAmount[1];
    },
    async fetchBoosterNftsAmounts() {
      if (!goldBoosterContract || !silverBoosterContract || !bronzeBoosterContract) {
        return;
      }
      this.amountGoldNFT = (await goldBoosterContract.balanceOf(this.wallet.address)).toNumber();
      this.amountSilverNFT = (await silverBoosterContract.balanceOf(this.wallet.address)).toNumber();
      this.amountBronzeNFT = (await bronzeBoosterContract.balanceOf(this.wallet.address)).toNumber();
    },
    async fetchBoosterNftsDetails() {
      if (!goldBoosterContract || !silverBoosterContract || !bronzeBoosterContract) {
        return;
      }

      const nfts: BoosterNFTInfo[] = [];

      const fetchBoosterInfo = async (contract: PermanentBoosterV2, amount: number, tier: number) => {
        for (let i = 0; i < amount; i++) {
          nfts.push({
            boostedLvpAmount: 0,
            maxBoostedLvpAmount: 0,
            tier: tier,
            tokenId: (await contract.tokenOfOwnerByIndex(this.wallet.address, i)).toNumber(),
          });
        }
      };

      await fetchBoosterInfo(goldBoosterContract, this.amountGoldNFT, 3);
      await fetchBoosterInfo(silverBoosterContract, this.amountSilverNFT, 2);
      await fetchBoosterInfo(bronzeBoosterContract, this.amountBronzeNFT, 1);

      if (nfts.length > 0 && this.LVPs.length > 0) {
        nfts[0].maxBoostedLvpAmount = 40;
        nfts[0].boostedLvpAmount = Math.min(this.LVPs.length, 40);
      }

      this.boosterNftsList = nfts;
    },
    async fetchWalletRoles() {
      if (!lvpContract || !goldBoosterContract || !silverBoosterContract || !bronzeBoosterContract) {
        return;
      }

      this.roles.lvpDistributor = await lvpContract.hasRole(ethers.utils.id("DISTRIBUTOR_ROLE"), this.wallet.address);
      this.roles.lvpMinter = await lvpContract.hasRole(ethers.utils.id("MINTER_ROLE"), this.wallet.address);
      this.roles.boosterMinter = await goldBoosterContract.hasRole(ethers.utils.id("MINTER_ROLE"), this.wallet.address);

      this.isAdmin = this.roles.lvpDistributor || this.roles.lvpMinter;
    },
    async fetchStatsForAdmin() {
      this.stats.boostedLVPsLvl1 = (await lvpContract!.totalBoostedLVPAmountT1()).toNumber();
      this.stats.boostedLVPsLvl2 = (await lvpContract!.totalBoostedLVPAmountT2()).toNumber();
      this.stats.boostedLVPsLvl3 = (await lvpContract!.totalBoostedLVPAmountT3()).toNumber();
    },
  },
});
