import { defineStore } from "pinia";
import { Contract, ethers } from "ethers";
import BigNumberJs from "bignumber.js";
import { calculatePriceOfPair, getTokenInfo } from "@/utils/tokenCache";
import smartContracts from "@/utils/SmartContracts";

import { fetchDataFromChain, publicProvider } from "@/utils/blockchain";
import { Investment } from "../../types";

export let investmentContract: ethers.Contract | undefined;

export interface InvestmentState {
  totalInvestorAmount: ethers.BigNumber;
  investments: Investment[];
}

const investments: Investment[] = [
  {
    wallet: "TBA",
    chainName: "ETH",
    allocation: "N/A",
    lockup: "9 months",
    vestingDate: "TBA",
    vestingPeriod: "24 months",
    ticker: "FLY",
    cost: "$0.50",
    tge: "TBA",
    tokenSupply: "100,000,000",
    tokensClaimed: "0",
    myContribution: "N/A",
    claimableAmount: "0",
    projectDescription:
      "A generic, cross chain message passing protocol to allow swaps between chains without bridging or using wrapped assets. Magpie protocol enables seamless cross-chain swaps with near-instant finality and cost efficiency on many of the top blockchains, all without the need to bridge any assets, making for an extremely fast, secure, easy, and gas efficient solution. Backed by investors including Jump, Wormhole, and Republic.",
    moreInfo: [],
    projectName: "Magpie Protocol",
    logo: "/img/magpie-logo.png",
    twitter: "https://twitter.com/magpieprotocol",
    discord: "https://discord.gg/CwJuFeHp6f",
    website: "https://www.magpiefi.xyz/",
  },
  {
    wallet: "TBA",
    chainName: "Shardeum",
    allocation: "N/A",
    lockup: "3 months",
    vestingDate: "TBA",
    vestingPeriod: "24 months",
    ticker: "SHM",
    cost: "$0.80",
    tge: "TBA",
    tokenSupply: "100,000,000",
    tokensClaimed: "0",
    myContribution: "N/A",
    claimableAmount: "0",
    projectDescription:
      "An upcoming EVM-compatible layer 1 blockchain focused on security and scalability through dynamic state sharding. It will be one of the first horizontally scalable blockchains, supporting growth by adding new nodes. Cofounded by the creator of Wazirx.",
    moreInfo: [],
    projectName: "Shardeum",
    logo: "/img/shardeum-logo.png",
    twitter: "https://twitter.com/shardeum",
    discord: "https://t.co/cer5zRVeao",
    website: "https://shardeum.org/",
  },
  {
    wallet: "TBA",
    chainName: "N/A",
    allocation: "N/A",
    lockup: "90 days",
    vestingDate: "TBA",
    vestingPeriod: "24 months",
    ticker: "MPC",
    cost: "N/A",
    tge: "TBA",
    tokenSupply: "100,000,000",
    tokensClaimed: "0",
    myContribution: "N/A",
    claimableAmount: "0",
    projectDescription:
      "A privacy preserving layer 1 that uses zero-knowledge computations and Multi-Party Computing to solve the blockchain trilemma. It functions as a transparent and structured platform for ZK computation. The team behind Partisia Blockchain represents world-leading cryptographers and pioneers within the commercial use of ZK computations.",
    moreInfo: [],
    projectName: "Partisia",
    logo: "/img/Partisia-logo.png",
    twitter: "https://twitter.com/partisiampc",
    discord: "https://discord.gg/5HX7j9xFS7",
    website: "https://partisiablockchain.com/",
  },
  {
    wallet: "TBA",
    chainName: "ETH",
    allocation: "N/A",
    lockup: "TBD",
    vestingDate: "TBA",
    vestingPeriod: "24 months",
    ticker: "TST",
    cost: "$0.06",
    tge: "TBA",
    tokenSupply: "100,000,000",
    tokensClaimed: "0",
    myContribution: "N/A",
    claimableAmount: "0",
    projectDescription:
      "An over-collateralized Stablecoin and market maker focused on international markets, with their first stable pegged to the euro. They're also building the tools to allow other organizations to issue and manage their own stables.",
    moreInfo: [],
    projectName: "The Standard",
    logo: "/img/thestandard-logo_cropped.png",
    twitter: "https://twitter.com/thestandard_io",
    discord: "https://t.co/JlA7z9qvPO",
    website: "https://www.thestandard.io/",
  },
  {
    wallet: "TBA",
    chainName: "ETH",
    allocation: "N/A",
    lockup: "TBD",
    vestingDate: "TBA",
    vestingPeriod: "5 months",
    ticker: "SPOT",
    cost: "$0.0275",
    tge: "TBA",
    tokenSupply: "100,000,000",
    tokensClaimed: "0",
    myContribution: "N/A",
    claimableAmount: "0",
    projectDescription:
      "A Decentralized Multichain Exchange for Digital Assets built on thorswap. Swap all assets frictionlessly in a decentralized manner, earn yield on your native coins and manage your portfolio all with full control of your keys.",
    moreInfo: [],
    projectName: "Defispot",
    logo: "/img/defispot-logo-modified.png",
    twitter: "https://discord.com/invite/defispot",
    discord: "https://t.co/JlA7z9qvPO",
    website: "https://defispot.com/",
  },
  {
    wallet: "TBA",
    chainName: "BSC",
    allocation: "N/A",
    lockup: "TBD",
    vestingDate: "TBA",
    vestingPeriod: "N/A",
    ticker: "BRDG",
    cost: "N/A",
    tge: "TBA",
    tokenSupply: "100,000,000",
    tokensClaimed: "0",
    myContribution: "N/A",
    claimableAmount: "0",
    projectDescription:
      "A decentralized cross-chain interoperability protocol that allows the transfer of assets across different chains by providing the tools a user might need to transact in the multichain world, including an NFT bridge, a token bridge, a cross-chain trading platform, cross-chain farming platform, a multi-chain explorer and a token wrapper for any existing token to go cross-chain.",
    moreInfo: [],
    projectName: "Bridge Network",
    logo: "/img/bridge-logo-modified.png",
    twitter: "https://twitter.com/bridgenetwork0x",
    discord: "https://discord.gg/PGHnWapB8u",
    website: "https://bridgenetwork.com/",
  },
  {
    wallet: "TBA",
    chainName: "Amplitude",
    allocation: "N/A",
    lockup: "TBD",
    vestingDate: "TBA",
    vestingPeriod: "N/A",
    ticker: "AMPE",
    cost: "N/A",
    tge: "TBA",
    tokenSupply: "100,000,000",
    tokensClaimed: "0",
    myContribution: "N/A",
    claimableAmount: "0",
    projectDescription:
      "Amplitude is a canary network of its sister blockchain, Pendulum. Its goal is connecting fiat rails to defi, via composable  services. It will act as a testing ground for applications and network parameters for Pendulum,  where users can trial novel concepts onchain with real financial consequences. ",
    moreInfo: [],
    projectName: "Amplitude",
    logo: "/img/amplitude-logo-modified.png",
    twitter: "https://twitter.com/amplitude_chain",
    discord: "https://discord.gg/wJ2fQh776B",
    website: "https://pendulumchain.org/amplitude",
  },
];

// NOTE: these variables are placed intentionally outside pinia store management, as these objects don't like proxies and don't work properly

export const useInvestmentStore = defineStore("investment", {
  state(): InvestmentState {
    return {
      totalInvestorAmount: ethers.BigNumber.from(20 + 34),
      investments,
    };
  },
  getters: {},
  actions: {
    async init() {
      investmentContract = new ethers.Contract(
        smartContracts.PrimaryContract.address,
        smartContracts.PrimaryContract.ABI,
        publicProvider
      );
      console.log("From the investment store");
      this.fetchInvestments();
    },
    async fetchInvestments() {
      if (!investmentContract) {
        return;
      }
      this.totalInvestorAmount = await investmentContract.getInvestments();
      console.log("From fetchInvestments");
    },
  },
});
