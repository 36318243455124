import { isOnMainnet } from "@/utils/environment";

const InvestmentContracts = {
  PrimaryContract: {
    address: "0xCFE5aE8468603b9Aa67C793f65f25DB7005Ef6E4",
    ABI: [
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "uint256",
            name: "index",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "address",
            name: "token",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "distributor",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "chainId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "amountInvested",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "investorDeadline",
            type: "uint256",
          },
        ],
        name: "InvestmentAdded",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "uint256",
            name: "index",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "snapshotTime",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "totalNodeValue",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "totalNodeAmount",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "totalInvestorAmount",
            type: "uint256",
          },
        ],
        name: "InvestmentSnapshot",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "caller",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "recipient",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "index",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "NodeAmountClaimed",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "previousOwner",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "newOwner",
            type: "address",
          },
        ],
        name: "OwnershipTransferred",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address",
            name: "account",
            type: "address",
          },
        ],
        name: "Paused",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "uint256",
            name: "tier",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "weight",
            type: "uint256",
          },
        ],
        name: "TierAllocationWeightUpdated",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "uint256",
            name: "tier",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "weight",
            type: "uint256",
          },
        ],
        name: "TierAmountWeightUpdated",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address",
            name: "account",
            type: "address",
          },
        ],
        name: "Unpaused",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "user",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "index",
            type: "uint256",
          },
          {
            indexed: true,
            internalType: "address",
            name: "token",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "UserInvestmentAdded",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "caller",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "recipient",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "index",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "UserInvestmentAmountClaimed",
        type: "event",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "_token",
            type: "address",
          },
          {
            internalType: "address",
            name: "_distributor",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "_chainId",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "_amountInvested",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "_investorDeadline",
            type: "uint256",
          },
        ],
        name: "addInvestment",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "index",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "token",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "addUserInvestment",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "anyCallAppId",
        outputs: [
          {
            internalType: "string",
            name: "",
            type: "string",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "anyCallContract",
        outputs: [
          {
            internalType: "address",
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "_to",
            type: "address",
          },
          {
            internalType: "bytes",
            name: "_data",
            type: "bytes",
          },
        ],
        name: "anyFallback",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "getInvestments",
        outputs: [
          {
            components: [
              {
                internalType: "address",
                name: "token",
                type: "address",
              },
              {
                internalType: "address",
                name: "distributor",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "chainId",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "amountInvested",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "userInvested",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "investorDeadline",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "snapshotTime",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "totalNodeValue",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "totalNodeAmount",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "totalInvestorAmount",
                type: "uint256",
              },
            ],
            internalType: "struct PrimaryChainInvestment.Investment[]",
            name: "",
            type: "tuple[]",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "user",
            type: "address",
          },
          {
            internalType: "uint256[]",
            name: "indices",
            type: "uint256[]",
          },
        ],
        name: "getNodeClaimAmount",
        outputs: [
          {
            components: [
              {
                internalType: "uint256",
                name: "index",
                type: "uint256",
              },
              {
                internalType: "address",
                name: "token",
                type: "address",
              },
              {
                internalType: "address",
                name: "distributor",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "chainId",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
              },
            ],
            internalType: "struct PrimaryChainInvestment.TempUserClaim[]",
            name: "claims",
            type: "tuple[]",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "recipient",
            type: "address",
          },
          {
            internalType: "uint256[]",
            name: "indices",
            type: "uint256[]",
          },
        ],
        name: "getNodeClaimGasFee",
        outputs: [
          {
            internalType: "uint256[]",
            name: "gasFees",
            type: "uint256[]",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "user",
            type: "address",
          },
        ],
        name: "getUserAllocation",
        outputs: [
          {
            internalType: "uint256",
            name: "allocation",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "user",
            type: "address",
          },
          {
            internalType: "uint256[]",
            name: "indices",
            type: "uint256[]",
          },
        ],
        name: "getUserInvestmentClaimAmount",
        outputs: [
          {
            components: [
              {
                internalType: "uint256",
                name: "index",
                type: "uint256",
              },
              {
                internalType: "address",
                name: "token",
                type: "address",
              },
              {
                internalType: "address",
                name: "distributor",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "chainId",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
              },
            ],
            internalType: "struct PrimaryChainInvestment.TempUserClaim[]",
            name: "claims",
            type: "tuple[]",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "recipient",
            type: "address",
          },
          {
            internalType: "uint256[]",
            name: "indices",
            type: "uint256[]",
          },
        ],
        name: "getUserInvestmentClaimGasFee",
        outputs: [
          {
            internalType: "uint256[]",
            name: "gasFees",
            type: "uint256[]",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "user",
            type: "address",
          },
        ],
        name: "getUserNodeWeight",
        outputs: [
          {
            components: [
              {
                internalType: "uint256",
                name: "creationTime",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "weight",
                type: "uint256",
              },
            ],
            internalType: "struct PrimaryChainInvestment.TempUserNodeWeight[]",
            name: "nodeWeights",
            type: "tuple[]",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "handleFallback",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "_usdc",
            type: "address",
          },
          {
            internalType: "address",
            name: "_usdce",
            type: "address",
          },
          {
            internalType: "address",
            name: "_lavaFinance",
            type: "address",
          },
          {
            internalType: "address",
            name: "_treasury",
            type: "address",
          },
        ],
        name: "initialize",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        name: "investments",
        outputs: [
          {
            internalType: "address",
            name: "token",
            type: "address",
          },
          {
            internalType: "address",
            name: "distributor",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "chainId",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "amountInvested",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "userInvested",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "investorDeadline",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "snapshotTime",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "totalNodeValue",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "totalNodeAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "totalInvestorAmount",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "lavaFinance",
        outputs: [
          {
            internalType: "contract ILavaFinance",
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "index",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "user",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
          {
            internalType: "bool",
            name: "isIncrease",
            type: "bool",
          },
        ],
        name: "manualNodeClaimAdjust",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "index",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "user",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
          {
            internalType: "bool",
            name: "isIncrease",
            type: "bool",
          },
        ],
        name: "manualUserInvestmentClaimAdjust",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "recipient",
            type: "address",
          },
          {
            internalType: "uint256[]",
            name: "indices",
            type: "uint256[]",
          },
          {
            internalType: "uint256[]",
            name: "gasToPay",
            type: "uint256[]",
          },
        ],
        name: "nodeClaim",
        outputs: [],
        stateMutability: "payable",
        type: "function",
      },
      {
        inputs: [],
        name: "owner",
        outputs: [
          {
            internalType: "address",
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "pause",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "paused",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "renounceOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "string",
            name: "_anyCallAppId",
            type: "string",
          },
        ],
        name: "setAnyCallAppId",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "_anyCallContract",
            type: "address",
          },
        ],
        name: "setAnyCallContract",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "bool",
            name: "_handleFallback",
            type: "bool",
          },
        ],
        name: "setHandleFallback",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "index",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "_snapshotTime",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "_totalNodeValue",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "_totalNodeAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "_totalInvestorAmount",
            type: "uint256",
          },
        ],
        name: "setInvestmentSnapshot",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "_lavaFinance",
            type: "address",
          },
        ],
        name: "setLavaFinance",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "tier",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "weight",
            type: "uint256",
          },
        ],
        name: "setTierAllocationWeight",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "tier",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "weight",
            type: "uint256",
          },
        ],
        name: "setTierAmountWeight",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "_treasury",
            type: "address",
          },
        ],
        name: "setTreasury",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        name: "tierAllocationWeight",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        name: "tierAmountWeight",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "newOwner",
            type: "address",
          },
        ],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "treasury",
        outputs: [
          {
            internalType: "address",
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "unpause",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "index",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "_amountInvested",
            type: "uint256",
          },
        ],
        name: "updateInvestmentAmount",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "index",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "_investorDeadline",
            type: "uint256",
          },
        ],
        name: "updateInvestmentDeadline",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "index",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "_token",
            type: "address",
          },
          {
            internalType: "address",
            name: "_distributor",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "_chainId",
            type: "uint256",
          },
        ],
        name: "updateInvestmentToken",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "usdc",
        outputs: [
          {
            internalType: "address",
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "usdce",
        outputs: [
          {
            internalType: "address",
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "recipient",
            type: "address",
          },
          {
            internalType: "uint256[]",
            name: "indices",
            type: "uint256[]",
          },
          {
            internalType: "uint256[]",
            name: "gasToPay",
            type: "uint256[]",
          },
        ],
        name: "userInvestmentClaim",
        outputs: [],
        stateMutability: "payable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        name: "userInvestments",
        outputs: [
          {
            internalType: "uint256",
            name: "amountInvested",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "amountClaimed",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "claimTime",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        name: "userNodeClaims",
        outputs: [
          {
            internalType: "uint256",
            name: "amountClaimed",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "claimTime",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],
  },
};

export default InvestmentContracts;
