import { Ref, ref } from "vue";

export const currentTheme: Ref<"dark" | "light"> = ref("light");

export function useLightMode() {
  currentTheme.value = "light";
  document.documentElement.classList.remove("dark");
  localStorage.setItem("theme", "light");
}

export function useDarkMode() {
  currentTheme.value = "dark";
  document.documentElement.classList.add("dark");
  localStorage.setItem("theme", "dark");
}

export function detectCorrectTheme() {
  let storedTheme = localStorage.getItem("theme");
  if (!storedTheme) {
    storedTheme = "dark";
    // if (
    //   window.matchMedia &&
    //   window.matchMedia("(prefers-color-scheme: dark)").matches
    // ) {
    //   storedTheme = "dark";
    // } else {
    //   storedTheme = "light";
    // }
  }

  if (storedTheme === "dark") {
    useDarkMode();
  } else {
    useLightMode();
  }
}

declare const Modernizr: any;
export const isWebmSupported = Modernizr.video && Modernizr.video.webm;

detectCorrectTheme();
