<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 103.62 81" fill="currentColor">
    <path
      d="M102.57,81c-3-2.38-6.08-4.78-9.14-7.13A7.62,7.62,0,0,0,86.84,72c-3.35.7-5.94,1.9-6.69,5.56a16.72,16.72,0,0,1-1.66,3.23l-10-21.33c-.26,2.39-.38,5.07-.88,7.68-1,5-7.36,7.73-11.86,5.33-4.7-2.51-8.67-5.54-9.93-11a3.54,3.54,0,0,0-.52-1,5.55,5.55,0,0,0-.78-.7l-5.2,14L31,66c-1.28,1.62-2.35,3-3.46,4.39-.9,1.1-1.84,2.17-2.77,3.24-5.24,6-5.73,6.07-10.39-.27-1.83-2.49-3.68-3.12-6.54-2A26.78,26.78,0,0,1,0,73.09c3.52-3.77,7.1-7.49,10.54-11.34,6.07-6.77,12.21-13.5,18-20.51C30.8,38.5,32.18,35.05,34,32c1.69-2.89,2.79-3.27,5.84-2.09,1.87.72,3,1.31,4.82,2.12,3.3,1.46,3.3,3.1,1.09,5.87a101.67,101.67,0,0,0-6.66,10.31c3.23-3.71,6.51-7.4,9.68-11.17,1.13-1.33,2-2.19,3.64-.56,1.18,1.2,2.2.65,3-.65a30.78,30.78,0,0,1,4.11-6c.55.72,1.07,1.13,1.6,1.77,8,9.6,16,19.22,24.23,28.68,2.15,2.49,5.13,4.26,7.33,6.7,3.82,4.23,7.33,8.74,11,13.12l-1.05.91"
    />
    <path
      d="M47.85,21.73C48,15.63,49.78,10.4,55.22,7,57.88,5.36,61,5.26,61.4,6.85c.74,2.7-1.69,3.65-3.43,4.49-4.69,2.25-7.59,6.09-10.12,10.39"
    />
    <path
      d="M52.19,23.6c2.09-3,7.52-4.85,10.63-3.39a4.31,4.31,0,0,1,2.11,2.67A3.31,3.31,0,0,1,62,24.49c-3.27-.09-6.53-.56-9.84-.89"
    />
    <path
      d="M44.92,14.9C42.6,12.54,40,10.1,37.66,7.42,37,6.64,36.1,5.25,36.86,4s2.77-.51,3.59,0c4.06,2.79,3.89,7.26,4.47,10.82"
    />
    <path
      d="M43.39,20.53c-3.22-1.61-6.07-1.09-8.83,1-.46.35-1.78.57-2.26-.1A3,3,0,0,1,33,18.66c2.76-2.94,8.16-2.07,10.35,1.87"
    />
    <path
      d="M47.9,6.22a12.76,12.76,0,0,1,.39-4.87A2.06,2.06,0,0,1,50.48,0c.74.28.34,1.65.06,2.27a36,36,0,0,1-2.34,4c-.24,0-.05,0-.3,0"
    />
  </svg>
</template>
