import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { useLavaStore } from "@/store/lava";
import { createToast } from "@/plugins/toastNotificationsPlugin";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/oldHome",
    name: "oldHome",
    component: () => import(/* webpackChunkName: "oldHome" */ "../views/Home.vue"),
  },
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "../views/HomeV2.vue"),
  },
  {
    path: "/my-nfts",
    name: "myNfts",
    component: () => import(/* webpackChunkName: "myNft" */ "../views/MyNfts.vue"),
  },
  {
    path: "/mint-node",
    name: "mintNode",
    component: () => import(/* webpackChunkName: "mintNode" */ "../views/MintNode.vue"),
  },
  // {
  //   path: "/node-fusion",
  //   name: "nodeFusion",
  //   component: () =>
  //     import(/* webpackChunkName: "nodeFusion" */ "../views/NodeFusion.vue"),
  // },
  // {
  //   path: "/treasury",
  //   name: "treasury",
  //   component: () =>
  //     import(/* webpackChunkName: "treasury" */ "../views/Treasury.vue"),
  // },
  // {
  //   path: "/bridge",
  //   name: "bridge",
  //   component: () =>
  //     import(/* webpackChunkName: "bridge" */ "../views/Bridge.vue"),
  // },
  // {
  //   path: "/swap",
  //   name: "swap",
  //   component: () => import(/* webpackChunkName: "swap" */ "../views/Swap.vue"),
  // },
  // {
  //   path: "/nfts",
  //   name: "nfts",
  //   component: () => import(/* webpackChunkName: "nfts" */ "../views/NFTs.vue"),
  // },
  {
    path: "/admin",
    name: "admin",
    component: () => import(/* webpackChunkName: "admin" */ "../views/AdminDashboard.vue"),
    beforeEnter: async (to, from) => {
      const lavaStore = useLavaStore();
      if (!lavaStore.isAdmin) {
        // wait 3 sec and try again
        await new Promise<void>((res) => {
          setTimeout(() => res(), 1500);
        });
        if (!lavaStore.isAdmin) {
          return { path: "/" };
        }
      }
    },
  },
  {
    path: "/investments",
    name: "investments",
    component: () => import(/* webpackChunkName: "nfts" */ "../views/Investments.vue"),
    beforeEnter: (to, from) => {
      const lavaStore = useLavaStore();
      if (lavaStore.LVPs.length > 0) {
        console.log("Access granted...");
        return true;
      } else {
        // reject the navigation
        console.log("cannot enter without nodes...");
        createToast({
          type: "error",
          text: "You need to be a nodeholder to access the investments section",
        });
        return { path: "/" };
      }
    },
  },
  {
    path: "/migration",
    name: "migration",
    component: () => import(/* webpackChunkName: "migration" */ "../views/Migration.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

export let cameFrom;
export default router;
