import { defineStore } from "pinia";
import { Contract, ethers } from "ethers";
import BigNumberJs from "bignumber.js";
import { calculatePriceOfPair, getTokenInfo } from "@/utils/tokenCache";
import smartContracts from "@/utils/SmartContracts";
import { fetchDataFromChain, publicProvider } from "@/utils/blockchain";

export interface PriceState {
  lavaUsdcPrice: BigNumberJs;
}

// NOTE: these variables are placed intentionally outside pinia store management, as these objects don't like proxies and don't work properly
const swapsicleFactoryContract = new ethers.Contract(
  smartContracts.swapsicle_factory.address,
  smartContracts.swapsicle_factory.ABI,
  publicProvider
);
let lavaUsdcPairContract: ethers.Contract;

export const usePriceStore = defineStore("price", {
  state(): PriceState {
    return {
      lavaUsdcPrice: new BigNumberJs(0),
    };
  },
  getters: {},
  actions: {
    async initPairContracts() {
      lavaUsdcPairContract = new ethers.Contract(
        await swapsicleFactoryContract.getPair(smartContracts.LAVAv2.address, smartContracts.erc20.USDC),
        smartContracts.tokenPair.ABI,
        publicProvider
      );
    },
    async fetchLavaUsdcPrice() {
      this.lavaUsdcPrice = await fetchDataFromChain(async () => {
        return await this.getPriceOfToken(smartContracts.LAVAv2.address, lavaUsdcPairContract);
      });
    },
    async getPriceOfToken(token: string, pairContract: Contract) {
      const [reserve0, reserve1]: ethers.BigNumber[] = await pairContract.getReserves();
      const tokenInfo0 = await getTokenInfo(await pairContract.token0());
      const tokenInfo1 = await getTokenInfo(await pairContract.token1());

      if (!tokenInfo0 || !tokenInfo1) {
        console.error("Error trying to getPriceOfToken", token);
        return new BigNumberJs(0);
      }
      return calculatePriceOfPair(
        { tokenInfo: tokenInfo0, amount: reserve0 },
        { tokenInfo: tokenInfo1, amount: reserve1 },
        token
      );
    },
  },
});
