import { ethers } from "ethers";
import BigNumberJs from "bignumber.js";
import { provider, useWalletStore } from "@/store/wallet";
import { getTokenSmartContract } from "@/utils/tokenCache";
import { createToast } from "@/plugins/toastNotificationsPlugin";
import { getReportedMessage } from "@/utils/errorHandling";
import { isOnTestnet } from "@/utils/environment";
import { AddEthereumChainParameter } from "../../types";

export const avaxChainId = parseInt(process.env.VUE_APP_CHAIN_ID!);
export const publicProvider = new ethers.providers.JsonRpcProvider(
  process.env.VUE_APP_PUBLIC_RPC,
  parseInt(process.env.VUE_APP_CHAIN_ID!)
);

export let blockTimestamp = 0;
export async function refetchBlockTimestamp() {
  if (isOnTestnet && provider) {
    const blockInfo = await provider.getBlock("latest");
    blockTimestamp = blockInfo.timestamp + 300;
  }
}

export const addTestnetParams: AddEthereumChainParameter = {
  chainId: "0x" + (31337).toString(16), // A 0x-prefixed hexadecimal string
  chainName: "LAVA: Avalanche Fork",
  nativeCurrency: {
    name: "GO",
    symbol: "GO", // 2-6 characters long
    decimals: 18,
  },
  rpcUrls: ["https://avaxfork.lava.financial/node"],
  blockExplorerUrls: null,
};

export const NULL_WALLET = "0x0000000000000000000000000000000000000000";

export function convertBigNumberToBigNumberJs(value: ethers.BigNumber, decimals = 18) {
  return new BigNumberJs(ethers.utils.formatUnits(value, decimals));
}

export function convertBigNumberJsToBigNumber(value: Omit<BigNumberJs, "_isBigNumber">, decimals = 18) {
  return ethers.utils.parseUnits(value.toFixed(), decimals);
}

export function formatBigNumber(val: ethers.BigNumber, decimal = 18, precision: number | null = null) {
  return formatBigNumberJs(convertBigNumberToBigNumberJs(val, decimal), precision);
}

export function formatBigNumberJs(number: Omit<BigNumberJs, "_isBigNumber">, precision: number | null = null) {
  if (precision === null) {
    precision = number.gte(10000) ? 0 : number.gte(1) ? 2 : 4;
  }
  return number.toFormat(precision, BigNumberJs.ROUND_FLOOR);
}

export function fetchDataFromChain<T>(
  fetchCallback: () => Promise<T>,
  hasValueChanged?: (newValue: T) => boolean,
  timesTried = 0
): Promise<T> {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    if (timesTried >= 5) {
      return reject();
    }

    try {
      const value = await fetchCallback();

      if (hasValueChanged && !hasValueChanged(value)) {
        setTimeout(() => {
          resolve(fetchDataFromChain(fetchCallback, hasValueChanged, timesTried++));
        }, 3000);
      } else {
        resolve(value);
      }
    } catch (err) {}
  });
}

export function fetchTokenBalance(tokenAddress: string, oldValue?: ethers.BigNumber) {
  return fetchDataFromChain<ethers.BigNumber>(
    async () => {
      const wallet = useWalletStore();
      if (!wallet.address) {
        return ethers.BigNumber.from(0);
      }
      const tokenContract = getTokenSmartContract(tokenAddress);
      if (!tokenContract) {
        throw new Error();
      }
      return await tokenContract.balanceOf(wallet.address);
    },
    oldValue ? (newValue) => !oldValue.eq(newValue) : undefined
  );
}

export async function makeCallWithPendingToast(props: {
  callTx: () => Promise<ethers.providers.TransactionResponse>;
  onSuccess?: () => void;
  onError?: (err) => void;
  waitingToastText?: string;
  successToastText?: string;
}) {
  let waitingToast;
  try {
    const tx = await props.callTx();

    if (props.waitingToastText) {
      waitingToast = createToast({
        type: "info",
        text: props.waitingToastText,
        duration: 0,
      });
    }

    await tx.wait();

    if (props.successToastText) {
      createToast({
        type: "success",
        text: props.successToastText,
      });
    }

    if (props.onSuccess) {
      props.onSuccess();
    }
  } catch (err: any) {
    if (props.onError) {
      props.onError(err);
    }

    createToast({
      type: "error",
      text: getReportedMessage(err),
    });
  } finally {
    if (waitingToast) {
      waitingToast.close();
    }
  }
}
